import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { Button } from "react-bootstrap";
import Hero from "../../components/hero";

import HorizontalTestimonial from "../../components/horizontal-testimonial";

const BusinessCoachSussex = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        heroImg: file(relativePath: { eq: "11.jpg" }) {
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        catherine: file(
          relativePath: { eq: "testimonials/catherine-clifford.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
        kevin: file(relativePath: { eq: "testimonials/kevin-hinton.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
        sarah: file(relativePath: { eq: "testimonials/sarah-bennett.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
      }
    `
  );
  const siteUrl = data.site.siteMetadata.siteUrl;
  const schemaImage = data.heroImg.publicURL;
  const title = "Business Coach in Sussex";
  const description =
    "Jason Cornes Business coaching for self-employed business owners. Feel you could benefit from business coaching in Sussex?";
  const heroImage = data.heroImg.childImageSharp.fluid;

  const catherine = data.catherine.childImageSharp.gatsbyImageData;
  const sarah = data.sarah.childImageSharp.gatsbyImageData;
  const kevin = data.kevin.childImageSharp.gatsbyImageData;
  const ogImg = `${siteUrl}${schemaImage}`;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Business Coaching",
        item: {
          url: `${siteUrl}/business-coaching`,
          id: `${siteUrl}/business-coaching`,
        },
      },
      {
        "@type": "ListItem",
        position: 3,
        name: "Sussex",
        item: {
          url: `${siteUrl}/business-coaching/sussex`,
          id: `${siteUrl}/business-coaching/sussex`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={title}
        description={description}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/business-coaching/sussex`,
          title: `${title}`,
          description: `${description}`,
          images: [
            {
              url: `${ogImg}`,
              width: 1920,
              height: 1080,
              alt: "Business Coaching in Sussex",
            },
          ],
        }}
      />
      <Hero
        isDarken={true}
        backgroundImage={heroImage}
        title="Business Coach Sussex"
        description1="Business coaching for self-employed business owners in Sussex"
        description2=""
      />
      <section className="py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="text-primary pb-3">
                Business coaching for self-employed business owners in Sussex.
              </h2>
              <p>Feel you could benefit from a business coach?</p>
              <p>
                I’m Jason Cornes; as an experienced business owner and business
                coach, it’s my job to help you:
              </p>
              <ul className="pl-4">
                <li>
                  Understand yourself better and play to your skills and
                  strengths
                </li>
                <li>Enjoy your work and achieve a better work life balance</li>
                <li>
                  Grow your business or scale your operations for a long-term
                  end goal.
                </li>
                <li>
                  Overcome assumptions or self-limiting beliefs that stop you
                  from making progress in your business
                </li>
                <li>
                  And finally, improve your operations, increase efficiency and
                  boost profitability.
                </li>
              </ul>
              <p>
                I tend to find business owners no matter what type of small
                business they own, fall into at least one of these categories;
                they need an independent sounding board, support to either put
                plans in place for growth, overcome obstacles (real or
                perceived) or make critical, often life-changing decisions. To
                sum up - most small business owners who reach out to me are
                ambitious but feeling stuck in some way...
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-primary text-white py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">Business Support in Sussex</h2>
              <p>
                If you search for the term ‘business support in Sussex’, Google
                serves up a staggering 76 million results. So, with literally
                millions of organisations, consultants and coaches offering to
                support your business - how do you choose?
              </p>
              <p>
                With local government support from the likes of East Sussex and
                West Sussex County Council, to courses and classes organised by
                local chambers of commerce and networking groups, not to mention
                the hundreds of franchised business coaches out there - there
                is, understandably, a lot to choose from.
              </p>
              <p>
                All I would say is, you and your business are unique. You have
                amazing assets, strengths and unique selling points, while on
                the flip side, you also have your very personal, individual
                circumstances and challenges.
              </p>
              <p>
                Generic ‘one size fits all’ business support won’t help you find
                your own individual pathway to success and get you to where you
                want to be in your life and business. This cookie-cutter style
                may work for some, but as I’ve found in my 35+ years in business
                and listening to the feedback of countless clients, a personal,
                tailor made 1-2-1 approach is the only effective way to make a
                difference.
              </p>
              <p>
                Which is where I can help. I don’t offer a templated,
                one-size-fits-all coaching service. My approach is to listen
                carefully to what you want and where you want to go with YOUR
                small business and support and guide you along your very
                personal journey to get you there.
              </p>
            </Col>
          </Row>
          <Row className="text-center pt-4">
            <Col>
              <Button
                variant="outline-light"
                className="cta-btn cta-btn-gtm"
                size="lg"
                as={Link}
                to="/contact-us"
              >
                Book a discovery meeting
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">
                Discover how my coaching has helped similar Sussex businesses.
              </h2>
              <p>
                Take Kevin an IT Consultant, Sarah a Commercial Photographer and
                Catherine a hotel owner. All three of these Sussex business
                owners came to me with their own individual personalities,
                circumstances, challenges and ambitions. So, what better than to
                read about my services in THEIR words.
              </p>
              <p>
                Kevin, Sarah and Catherine wrote the following local
                testimonials:
              </p>
            </Col>
          </Row>
          <HorizontalTestimonial
            name="Kevin Hinton – kITman Limited"
            review="I have used Jason as a Business Coach. Jason is unique in the fact
          that he actually listens to what the business owner requires. And does
          not work from a preformed business builder script. Along with his
          knowledge in personal psychology, he can identify problems that can be
          holding the business back. I can highly recommend using Jason – to
          develop your business"
            linkedIn="https://www.linkedin.com/in/kevin-hinton-b7a28318/"
            profilePic={kevin}
          />

          <HorizontalTestimonial
            name="Sarah Bennett Commercial Photography."
            review="I’ve worked with Jason for two years this month. When I came to Jason
          for help, I wasn’t entirely sure what a business coach did, I thought
          maybe a few sessions then that was it..2 years on and I can truly say
          my business has gone from strength to strength, but I still need him.
          Jason is the person I tell all my ideas to, and bounce them off. He’s
          the one person that will give me an honest answer and opinion, he
          tells me what I need to hear, not necessarily what I want to hear.
          Because he’s always run his own businesses, he knows his stuff, it's
          based on training yes, but more importantly I fell on his 30 years of
          experience in running his own companies. His ideas are inspiring and I
          can’t recommend him enough to others"
            linkedIn="https://www.linkedin.com/in/sarahbennettcommercial/"
            profilePic={sarah}
          />

          <HorizontalTestimonial
            name=" Catherine Clifford. Managing Partner. Best Western Lansdowne Hotel."
            review="I met Jason at a networking event and straight away I found him to be
          very easy to talk to and I felt immediately at ease with him. I booked
          a session with him and I can honestly say that now, after several
          sessions, I have truly found a way forward for myself and my business.
          After a simple question about what it was that I actually wanted, it
          opened the floodgates to a huge amount of possibility that was there
          but I hadn’t had the courage to tap into. The coaching really helped
          me to find perspective and clear clouds that were thick and heavy in
          my mind about tackling challenges and moving forward . It’s fair to
          say that the clarity and confidence that I have gained from Jason’s
          business coaching has been invaluable. Thank you Jason!!"
            linkedIn="https://www.linkedin.com/in/catherineclifford72/"
            profilePic={catherine}
          />
        </Container>
      </section>

      <section className="bg-white py-5">
        <Container>
          <Row className="g-5 justify-content-md-center">
            <Col lg={12}>
              {" "}
              <div className="iframe-container-new mb-4">
                <iframe
                  loading="lazy"
                  className="responsive-iframe"
                  src="https://www.youtube.com/embed/7F0Rj6Vtos8"
                  title="Testimonial | Jason Cornes"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-tertiary text-white py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">
                Ready to get the support your Sussex business needs?
              </h2>
              <p>
                Business coaching is personal to the business owner and to be
                effective, it must be bespoke and flexible. With this in mind,
                I’d love you to join my list of happy and successful
                self-employed and director-led business coaching clients across
                the trades, health and wellness, professional services, and even
                the not for profit and charitable sector.
              </p>
              <p>
                I live in Pevensey, close to Eastbourne, I have travelled in the
                past throughout Sussex for face-to-face caching and likewise
                clients come to me. Alternatively, we can speak remotely via
                Zoom or Skype which works very well.
              </p>
              <p>
                So, if you’d like to explore how you’ll benefit from some
                business support in Sussex, from a coach with a proven track
                record of success with their clients - let’s chat. Click below
                to book an introductory call with me.
              </p>
            </Col>
          </Row>
          <Row className="text-center pt-4">
            <Col>
              <Button
                variant="outline-light"
                className="cta-btn cta-btn-gtm"
                size="lg"
                as={Link}
                to="/contact-us"
              >
                Get in Touch
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <CtaBanner
        headline="Business Coaching in Sussex"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
    </Layout>
  );
};
export default BusinessCoachSussex;
